import React from 'react'
import { RestaurantLocationProps } from '../../../../../themes/gatsby-theme-beluga/src/components/RestaurantLocation'
import { OutboundLink } from 'gatsby-plugin-google-gtag'
import Button from '../../../../../themes/gatsby-theme-beluga/src/components/Button'

export default function GiftCertificatesButton({
  locations
}: {
  locations: Array<RestaurantLocationProps>
}) {
  const href = locations[0].pickupUrl

  return (
    <Button
      component={OutboundLink}
      href={href}
      size="large"
      className="mb-20 shadow-lg shadow-black/25"
    >
      Order Gift Certificates
    </Button>
  )
}
